import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faJenkins } from '@fortawesome/free-brands-svg-icons';
import { faComputer } from '@fortawesome/free-solid-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Expertise.scss';

const labelsFirst = [
    "Swift",
    "Objective-C",
    "SwiftUI",
    "UIKIt",
    "WebKit",
    "MapKit",
    "AVKit",
    "Combine",
    "Realm",
    "Core Data",
    "Chromium"
];

const labelsSecond = [
    "Jenkins",
    "Git",
    "GitHub Actions",
    "Docker",
    "Azure",
    "Linux",
    "GitLab CI",
    "Unit Testing",
    "fastlane",
];

const labelsThird = [
    "C++",
    "AppKit",
    "Electron",
    "WinAPI",
    "CMake",
    "protobuf",
    "Python"
];

function Expertise() {
    return (
    <div className="container" id="expertise">
        <div className="skills-container">
            <h1>Expertise</h1>
            <div className="skills-grid">
                <div className="skill">
                    <FontAwesomeIcon icon={faApple} size="3x"/>
                    <h3>iOS Development</h3>
                    <p>I have built a diverse array of iOS and macOS applications from scratch using modern technologies such as SwiftUI, UIKit. I have a strong proficiency in the SDLC process and frontend + backend development.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsFirst.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faJenkins} size="3x"/>
                    <h3>DevOps & Automation</h3>
                    <p>During the application's development, I set up DevOps practices, implement automation testing, and establish CI/CD pipelines and deployment automation to support a successful launch.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsSecond.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faComputer} size="3x"/>
                    <h3>Desktop Development</h3>
                    <p>To advance my expertise further, I started cross-platform desktop development for macOS and Windows. My experience with iOS helps me quickly get up to speed and achieve great results.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsThird.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Expertise;