import React from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';

import '../assets/styles/Main.scss';

import avatar from "../assets/images/avatar.png";

function Main() {

  const links = (
    <div>
      <a href="https://www.facebook.com/Ihorcko" style={{margin: '2px'}} target="_blank" rel="noreferrer"><FacebookIcon/></a>
      <a href="http://github.com/IhorShevchuk" style={{margin: '2px'}} target="_blank" rel="noreferrer"><GitHubIcon/></a>
      <a href="https://linkedin.com/in/IhorShevchuk" style={{margin: '2px'}} target="_blank" rel="noreferrer"><LinkedInIcon/></a>
      <a href="https://twitter.com/@IhorShevchuk" style={{margin: '2px'}} target="_blank" rel="noreferrer"><TwitterIcon/></a>
    </div>
  )

  return (
    <div className="container">
      <div className="about-section">
        <div className="image-wrapper">
          <img src={avatar} alt="Avatar" />
        </div>
        <div className="content">
          <div className="social_icons">
            {links}
          </div>
          <h1>Ihor Shevchuk</h1>
          <p>Software Engineer</p>

          <div className="mobile_social_icons">
            {links}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;