import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../assets/styles/Timeline.scss'

function Timeline() {
  const SoftServeLogoImage = (<img src={require('../assets/images/softserve_logo.png')} style={{width: '50%' , margin: '25%' }} alt="Softserve Logo" />)
  const TechMagicLogoImage = (<img src={require('../assets/images/techmagic_logo.png')} style={{width: '70%' , margin: '15%' }} alt="Techmagic Logo" />)
  const MetaLogoImage = (<img src={require('../assets/images/meta_logo.png')} style={{width: '90%' , margin: '5%' }} alt="Meta Logo" />)
  const EmplyerIconStyle = ({ background: 'white' })
  return (
    <div id="history">
      <div className="items-container">
        <h1>Professional Journey</h1>
        <VerticalTimeline>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2024"
            iconStyle={EmplyerIconStyle}
            icon={MetaLogoImage}
          >
            <h3 className="vertical-timeline-element-title">Software Engineer</h3>
            <h4 className="vertical-timeline-element-subtitle"><a href="https://www.meta.com/" target="_blank" rel="noreferrer">Meta</a></h4>
            <p>
            Building solutions to bring people closer together using cutting-edge technologies
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2023"
            iconStyle={EmplyerIconStyle}
            icon={SoftServeLogoImage}
          >
            <h3 className="vertical-timeline-element-title">Senior Software Engineer</h3>
            <h4 className="vertical-timeline-element-subtitle"><a href="https://www.softserveinc.com/" target="_blank" rel="noreferrer">SoftServe</a></h4>
            <p>
              Moved on to new horizons in C++ desktop development, helping to build a cross-platform application for configuring PC peripherals
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2021"
            iconStyle={EmplyerIconStyle}
            icon={SoftServeLogoImage}
          >
            <h3 className="vertical-timeline-element-title">Lead iOS Software Engineer</h3>
            <h4 className="vertical-timeline-element-subtitle"><a href="https://www.softserveinc.com/" target="_blank" rel="noreferrer">SoftServe</a></h4>
            <p>
              Got promoted to Lead Software Engineer of the iOS team on a secure web browser project
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2016"
            iconStyle={EmplyerIconStyle}
            icon={SoftServeLogoImage}
          >
            
            <h3 className="vertical-timeline-element-title">iOS Software Engineer</h3>
            <h4 className="vertical-timeline-element-subtitle"><a href="https://www.softserveinc.com/" target="_blank" rel="noreferrer">SoftServe</a></h4>
            <p>
              Joined very promising project: Chromium+WebKit iOS web browser, focused on security and performance
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2014"
            iconStyle={EmplyerIconStyle}
            icon={TechMagicLogoImage}
          >
            <h3 className="vertical-timeline-element-title">iOS Software Engineer</h3>
            <h4 className="vertical-timeline-element-subtitle"><a href="https://www.techmagic.co" target="_blank" rel="noreferrer">TechMagic inc.</a></h4>
            <p>
              Started my software development journey, leading the creation of a location-based event app and a children's social network using BLE devices
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default Timeline;