import React from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import '../assets/styles/Footer.scss'

function Footer() {
  return (
    <footer>
      <div>
        <a href="https://www.facebook.com/Ihorcko" target="_blank" rel="noreferrer"><FacebookIcon/></a>
        <a href="http://github.com/IhorShevchuk" target="_blank" rel="noreferrer"><GitHubIcon/></a>
        <a href="https://linkedin.com/in/IhorShevchuk" target="_blank" rel="noreferrer"><LinkedInIcon/></a>  
        <a href="https://twitter.com/@IhorShevchuk" target="_blank" rel="noreferrer"><TwitterIcon/></a>
      </div>
    </footer>
  );
}

export default Footer;